import React from "react";

import Layout from "../components/layout";
import Listing from "../components/listing";
import SEO from "../components/seo";

const News = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Neuigkeiten"
      description="Auflistung der neusten Blog-Artikel."
    />
    <div>
      <h1>Neuigkeiten</h1>
      <Listing />
    </div>
  </Layout>
);

export default News;
