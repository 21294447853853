import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

const TITLE_PAGE_LISTING_QUERY = graphql`
  query TitlePageListing {
    allMarkdownRemark(
      limit: 10
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            slug
            date(formatString: "DD. MMMM YYYY", locale: "de")
          }
        }
      }
    }
  }
`;

const Listing = () => {
  return (
    <>
      <StaticQuery
        query={TITLE_PAGE_LISTING_QUERY}
        render={({ allMarkdownRemark }) =>
          allMarkdownRemark.edges.map(({ node }) => (
            <article className="article-preview" key={node.frontmatter.slug}>
              <Link to={`/posts${node.frontmatter.slug}`}>
                <h3>{node.frontmatter.title}</h3>
              </Link>
              <h6>{node.frontmatter.date}</h6>
              <p>{node.excerpt}</p>
              <Link className="read-more" to={`/posts${node.frontmatter.slug}`}>
                Mehr lesen...
              </Link>
            </article>
          ))
        }
      />
    </>
  );
};

export default Listing;
